 
import React from 'react'; 
import PrivacyBlock from '../../components/PrivacyBlock';  
import './style.css'; 

function DocumentsPageScreen(props) {
 
  const { mobile, type } = props; 
 
  return (
    <div id="toppage" className="page_screen"> 
      <div className={`${mobile ? 'mobile_page_wrapper':'page_wrapper'}`}> 
        <PrivacyBlock mobile={mobile} type={type} />  
      </div>
    </div>
  );
}

export default DocumentsPageScreen;
