import api from "../http";

export default class UsersServices {

  static getUsersFromHome(city, street, number) {
      return api.main_api.post('/users/home',{city, street, number})
  }
  static async addMoney(email,obj) {
    return api.main_api.post('/addMoney',{ email,obj })
  }

  static async decMoney(email,sum) { 
    return api.main_api.post('/decMoney',{ email, sum })
  }

  static async sendMoneyMail(email , id, sum, numbercard) {
    return api.main_api.post('/sendMoneyMail',{ email , id, sum, numbercard })
  }

  static async setOutMoney(email, obj) {
    return api.main_api.post('/setOutMoney',{ email, obj })
  }
}
