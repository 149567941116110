 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { json, useParams } from 'react-router-dom';
import Title from '../../components/Title';  
import NavigateHeader from '../../components/NavigateHeader';  
import PageTitleBlock from '../../components/PageTitleBlock';   
import PageInfoBlock from '../../components/PageInfoBlock';   
import PageQrBlock from '../../components/PageQrBlock';  
import PagePhotoBlock from '../../components/PagePhotoBlock';   
import images from '../../assets/images'; 
import './style.css'; 

function ExamplePageScreen(props) {
 
  const { mobile } = props; 
  const dispatch = useDispatch();  

 
  return (
    <div className="page_screen"> 
      <div id="toppage" className={`${mobile ? 'mobile_page_wrapper':'page_wrapper'}`}>
        {
          mobile ?
          <>
            <Title margin="110px 0px 0px 0px" size={33} text={`Категория: `} />   
          </>
          :
          <> 
            <NavigateHeader idPage={'examplepage'} /> 
            <PageTitleBlock 
              photo={ images.examplephoto }
              info={ 'ФЕДОТОВ ВЛАДИМИР АЛЕКСЕЕВИЧ' }
              epity={ `
                Ветер в окна задувает, Сушит мокрые ресницы. Как тебя нам не хватает! На плече твоём забыться... 
                Невозвратная потеря, Словно душу надломили... 
                До сих пор ещё не верю, Что ты где-то в звёздной пыли. Задувает в окна ветер, От тебя он прилетает.
              ` }
              birthdate = { '1931-02-27' }
              deathdate = { '2020-06-09' }
            /> 
            <PageInfoBlock 
              birthlocation={ 'Россия' } 
              deathlocation={ 'Россия' } 
              career={ 'Учитель' } 
              children={JSON.stringify(['есть'])} 
              education={ 'Высшее' } 
              nationality={ 'Русский' } 
              secondhalf={ 'Федотова Наталья Паловна' } 
            /> 
            <PagePhotoBlock /> 
            <PageQrBlock examplepage={true} id="qr" />  
          </>
        }
      </div>
    </div>
  );
}

export default ExamplePageScreen;
