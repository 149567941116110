import React from 'react'; 
 
import './style.css';

function TableCol(props) {
    const { services, dots, title, mobile } = props;
 
    return (
      <div className={`${mobile ? 'mobileTableColWrapper' : 'tableColWrapper'}`}> 
        {
          services && 
          <div className='serviceContainer'>
            <div style={mobile ? {paddingTop: '7px'} : {}} className='serviceTitle'>Опции</div>
            {
              services.map((item,key) => (<div key={key} className='serviceCol'>{item}</div>))
            }
          </div>
        }
        {
          dots && 
          <div className='dotsContainer'>
            <div className='dotsTitle'>{title}</div>
            {
              [1,1,1,1,1,1].map((item,key) => (<div key={key} className='dotsCol'><div className={`${key < dots ? 'create__row-value-inner' : ''}`}></div></div>))
            }
          </div>
        }
      </div>
    );
}

export default TableCol;
 