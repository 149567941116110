const ActionType = {
  HEADER_TAB: 'HEADER_TAB', 
  HEADER_POPOVER: 'HEADER_POPOVER', 
 
  USERS_PUT_USER: "USERS_PUT_USER",
  USERS_PUT_USERS_HOME: "USERS_PUT_USERS_HOME",
  USERS_PUT_ISAUTH: "USERS_PUT_ISAUTH",
  USERS_PUT_UNTILDAY:"USERS_PUT_UNTILDAY",
  USERS_PUT_RESTATE:"USERS_PUT_RESTATE",
    
  POPUP_LOGIN_VISIBLE:"POPUP_LOGIN_VISIBLE",
  POPUP_LOGIN_ENTER:"POPUP_LOGIN_ENTER",
  
  POPUP_REFERAL_VISIBLE:"POPUP_REFERAL_VISIBLE",
  
  APP_MOBILE: "APP_MOBILE",
  APP_SOCKET: "APP_SOCKET", 
  APP_WORK_DATA_USER: "APP_WORK_DATA_USER",
  APP_WORK_DATA_ROOM: "APP_WORK_DATA_ROOM",
  APP_SUBPAGES_SCREEN:"APP_SUBPAGES_SCREEN", 
  APP_LOADING:"APP_LOADING",   
  APP_SIDE_MENU:"APP_SIDE_MENU",
  APP_PAGES_SCREEN:"APP_PAGES_SCREEN",
  APP_HEADER_HIDDEN_CHANGE:"APP_HEADER_HIDDEN_CHANGE",
  APP_HEADER_ACTIVE_CHANGE:"APP_HEADER_ACTIVE_CHANGE", 
  APP_SIDE_MENU_STATE:"APP_SIDE_MENU_STATE",  
  

  PAGEINFO_BACKGROUNDSELECT:"PAGEINFO_BACKGROUNDSELECT",
  PAGEINFO_EDITPAGE:"PAGEINFO_EDITPAGE",
  PAGEINFO_CONSULT_COMENT:'PAGEINFO_CONSULT_COMENT',
  PAGEINFO_CONSULT_BYER_TEL:'PAGEINFO_CONSULT_BYER_TEL',
  PAGEINFO_CONSULT_BYER_INITIAL:'PAGEINFO_CONSULT_BYER_INITIAL',
  PAGEINFO_POPUP_MAIN:'PAGEINFO_POPUP_MAIN',
  PAGEINFO_BYER_FILE:'PAGEINFO_BYER_FILE',
  PAGEINFO_BYER_DEL_FILE:'PAGEINFO_BYER_DEL_FILE',
  PAGEINFO_DEATH_INITIAL:'PAGEINFO_DEATH_INITIAL',
  PAGEINFO_DEATH_DATE:'PAGEINFO_DEATH_DATE',
  PAGEINFO_BIRTH_DATE:'PAGEINFO_BIRTH_DATE',
  PAGEINFO_NATIONALITY:'PAGEINFO_NATIONALITY',
  PAGEINFO_BIRTH_LOCATION:'PAGEINFO_BIRTH_LOCATION',
  PAGEINFO_DEATH_LOCATION:'PAGEINFO_DEATH_LOCATION',
  PAGEINFO_EPITY:'PAGEINFO_EPITY',
  PAGEINFO_CHILDREN:'PAGEINFO_CHILDREN',
  PAGEINFO_SECOND_HALF:'PAGEINFO_SECOND_HALF',
  PAGEINFO_CAREER:'PAGEINFO_CAREER',
  PAGEINFO_EDUCATION:'PAGEINFO_EDUCATION', 
  PAGEINFO_GENERATE_PAGE:'PAGEINFO_GENERATE_PAGE', 
  PAGEINFO_ONEBLOCKAREA: 'PAGEINFO_ONEBLOCKAREA',
  PAGEINFO_ONEBLOCKINPUTTITLE: 'PAGEINFO_ONEBLOCKINPUTTITLE',
  PAGEINFO_ONEBLOCKONEINPUT: 'PAGEINFO_ONEBLOCKONEINPUT',
  PAGEINFO_ONEBLOCKTWOINPUT: 'PAGEINFO_ONEBLOCKTWOINPUT',
  PAGEINFO_TWOBLOCKAREA: 'PAGEINFO_TWOBLOCKAREA', 
  PAGEINFO_TWOBLOCKINPUTTITLE:'PAGEINFO_TWOBLOCKINPUTTITLE',
  PAGEINFO_TWOBLOCKONEINPUT: 'PAGEINFO_TWOBLOCKONEINPUT',
  PAGEINFO_TWOBLOCKTWOINPUT: 'PAGEINFO_TWOBLOCKTWOINPUT',
  PAGEINFO_THREEBLOCKAREA: 'PAGEINFO_THREEBLOCKAREA',  
  PAGEINFO_REGESTRATION_TEL: 'PAGEINFO_REGESTRATION_TEL',  

  
  CATALOG_CATEGORY:'CATALOG_CATEGORY',
  CATALOG_BUY:'CATALOG_BUY',
  CATALOG_BUYDEL:'CATALOG_BUYDEL',
  CATALOG_INCRIMENTBUY:'CATALOG_INCRIMENTBUY', 
  CATALOG_DECRIMENTBUY:'CATALOG_DECRIMENTBUY',
  CATALOG_BUYCLEAR:'CATALOG_BUYCLEAR',
  CATALOG_LINKPAGE:'CATALOG_LINKPAGE',
  CATALOG_SENDEMAILSERVER:'CATALOG_SENDEMAILSERVER',
  CATALOG_WIDTHMONUMENT:'CATALOG_WIDTHMONUMENT',
  CATALOG_MATERIALMONUMENT:'CATALOG_MATERIALMONUMENT',
  CATALOG_FILENAME:'CATALOG_FILENAME',

  CART_POPUP_MAIN:'CART_POPUP_MAIN',

  CATEGORIES_SET:"CATEGORIES_SET",
 
  DIALOGS_SET_ACTIVE:"DIALOGS_SET_ACTIVE",
  
  MESSAGES_SET_ACTIVE:"MESSAGES_SET_ACTIVE",

  ROOMS_SET_ACTIVE:"ROOMS_SET_ACTIVE",
  ROOMS_SET_ALL:"ROOMS_SET_ALL",
  ROOMS_SET_JOIN:"ROOMS_SET_JOIN",


}

export default ActionType;
