import React from 'react';
import Answer from '../../components/Answer';
import Title from '../Title';
import { answers } from '../../config';
import './style.css';
 
function AnswerBlock(props) {
  const { header, mobile, id } = props;
  let st = {fontSize: '10px'}
    return (
      <div className={`${mobile ? 'mobileAnswerBlock_container' : "answerblock_container"}`}>
      {/* <div id={id} style={{height: '160px', width: '100%'}} /> */}
        {
          mobile ? 
          <>
            <Title size={28} margin="20px 0px 30px 0px" text="ВОПРОСЫ И ОТВЕТЫ" under /> 
            {
              answers.map((item,i) => ( 
              <Answer id={i == 5 ? id : `${i}_ans`} mobile={mobile} style={st} title={item.request} description={item.response} key={i} />
              ))
            }
          </>
          :
          <>
            <Title margin="50px 0px 30px 0px" text="ВОПРОСЫ И ОТВЕТЫ" under />
            <div className="answerblock_container_header">{header}</div>
            {
              answers.map((item,i) => ( 
              <Answer id={i == 5 ? id : `${i}_ans`} mobile={mobile} style={st} title={item.request} description={item.response} key={i} />
              ))
            }
          </>
        }
      </div>
    );
}

export default AnswerBlock;
