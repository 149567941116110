 
export const app = {
  mobile: (state) => state.app.mobile
}

export const pages = {
  page:(state) => state.pages.page,
  header_visible:(state) => state.pages.header_visible,
  active:(state) => state.pages.active,
}

export const header = {
  open:(state) => state.header.open, 
}
 
export const popup_login = {
  popup_visible:(state) => state.popup_login.popup_visible,
  enter:(state) => state.popup_login.enter, 
  login_data:(state) => state.popup_login.login_data,
}
 
export const popup_referal = {
  popup_visible:(state) => state.popup_referal.popup_visible, 
}

export const loader = {
  loading:(state) => state.loader.loading,
}

export const generatepages = {
  generatepage:(state) => state.generatepages.generatepage,
}

export const users = {
  user:(state) => state.users.user,
  isAuth:(state) => state.users.isAuth,
  untilDay:(state) => state.users.untilDay,
  restate:(state) => state.users.restate,
}
  
export const pageinfo = {
  byer_file: (state) => state.pageinfo.byer_files,
  initialDeath: (state) => state.pageinfo.initialDeath,
  deathdate: (state) => state.pageinfo.deathdate,
  birthdate: (state) => state.pageinfo.birthdate,
  nationality: (state) => state.pageinfo.nationality,
  birthlocation: (state) => state.pageinfo.birthlocation,
  deathlocation: (state) => state.pageinfo.deathlocation,
  epity: (state) => state.pageinfo.epity,
  children: (state) => state.pageinfo.children,
  secondhalf: (state) => state.pageinfo.secondhalf,
  coment: (state) => state.pageinfo.coment, 
  byer_consult_initial: (state) => state.pageinfo.byer_consult_initial,
  byer_consult_tel: (state) => state.pageinfo.byer_consult_tel,
  coment_consult: (state) => state.pageinfo.coment_consult,
  education: (state) => state.pageinfo.education,
  career: (state) => state.pageinfo.career,
  msg_main_popup: (state) => state.pageinfo.msg_main_popup,
  editpage: (state) => state.pageinfo.editpage,
  backgrounds: (state) => state.pageinfo.backgrounds,
  backgroundselect: (state) => state.pageinfo.backgroundselect, 
  registartion_tel: (state) => state.pageinfo.registartion_tel, 
 
  OneBlockArea: (state) => state.pageinfo.OneBlockArea,  
  OneBlockInputTitle: (state) => state.pageinfo.OneBlockInputTitle,  
  OneBlockOneInput: (state) => state.pageinfo.OneBlockOneInput, 
  OneBlockTwoInput: (state) => state.pageinfo.OneBlockTwoInput, 

  TwoBlockArea: (state) => state.pageinfo.TwoBlockArea,  
  TwoBlockInputTitle: (state) => state.pageinfo.TwoBlockInputTitle,  
  TwoBlockOneInput: (state) => state.pageinfo.TwoBlockOneInput, 
  TwoBlockTwoInput: (state) => state.pageinfo.TwoBlockTwoInput, 

  ThreeBlockArea: (state) => state.pageinfo.ThreeBlockArea,      
 
}
 

