import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';   
import MiniPageBlock from '../../components/MiniPageBlock';  
import images from '../../assets/images';
import { checkAuth, set_restate,deletePage, getUserInfo,getPageInfo,set_user,set_user_isauth, getInfoUntilDays } from '../../redux/actions/users'
import { products } from '../../config';
import { setGeneratePage } from '../../redux/actions/pageinfo'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { pageinfo} from '../../redux/selectors'; 
import './style.css'; 
 
 
function MyPagesScreen(props) {

  const { mobile, isAuth, user } = props;
  const { quotl, quotr, profile } = images;

  const { idPage } = useParams()
  const dispatch = useDispatch(); 

  const coment_consult = useSelector(pageinfo.coment_consult);  
  
  const [deleter, setDeleter] = useState(false);

  useEffect(() => {    
    const fetchData = async () => {
      const user = await getUserInfo(dispatch) 
 
      if(user !== 401) { 
        dispatch(set_user_isauth(true));
        dispatch(set_user(user));  
      }    
    }; 
    fetchData(); 
  },[ ]); 
 
  let pagesArr = []
 


  if(user?.pages !== undefined && user?.pages.length !== 0) { 
    user?.pages.map((item, key) => {
      pagesArr.push(<MiniPageBlock  
        onDeleted={async (e) => {
          setDeleter(true);
          if(item.pageId === e) {
            let del = await deletePage(e, user, dispatch)
            setDeleter(del)
          } 
      }} 
      payinfo={user.payinfo}
      disabled={deleter} 
      mobile={mobile} 
      key={key} 
      pageId={item.pageId} 
      photo={item.photo !== null ? item.photo : profile} 
      fio={item.initialDeath} 
      birthdate={item.birthdate} 
      deathdate={item.deathdate} 
      /> ) 
    })
    pagesArr.push(<MiniPageBlock mobile={mobile} key={`${Math.random()}a`} nopage={true} />)
  } else {
    pagesArr.push(<MiniPageBlock mobile={mobile} key={`${Math.random()}aa`} nopage={true} />)
  }
 
    return (
      <div className="mypage_screen"> 
        <div className='mypage_wrapper'> 
          <Title size={mobile ? 30 : undefined} margin="50px 0px 0px 0px" text="Мои страницы памяти" under />  
          <div className={`${mobile ? 'mobileMiniPageContainer' : 'miniPageContainer'}`}>
            { 
              pagesArr
            } 
          </div>  
        </div>
      </div>
    );
}

export default MyPagesScreen;
