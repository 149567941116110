import React from 'react';
import './style.css';

function FooterLinkButton(props) {
    const { text, width, height, onClick, margin, disabled, mobile } = props;
    return (
      <div 
        style={{ 
          pointerEvents: disabled ? 'none' : 'all', 
          width: width === undefined ? '150px': width,
          height: height === undefined ? '40px' : height,
          margin: margin === undefined ? '0px' : margin,
          background: disabled ? '#c7c7c7' : 'rgb(55 62 78)',  
        }}  
        className={`${mobile ? 'mobileWrapperFooterLinkButton' : "wrapperFooterLinkButton"}`}
        onClick={onClick === undefined ? () => {} : () => onClick()}
      >
        <div className={`${mobile ? 'mobileTextFooterLinkButton' : "textFooterLinkButton"}`}>{ text }</div>
      </div>
    );
}

export default FooterLinkButton;
