import React from 'react';
import './style.css';

function OrangeButton(props) {
    const { text, width, height, color, onClick, margin, disabled, styles,id} = props;
    return (
      <div 
        id={id}
        style={{  
          pointerEvents: disabled ? 'none' : 'all', 
          width: width === undefined ? '150px': width,
          height: height === undefined ? '40px' : height,
          margin: margin === undefined ? '0px' : margin,
          background: disabled ? '#c7c7c7' : `linear-gradient(0deg, ${color === undefined ? 'rgb(66 141 255)': color} 0%, ${color === undefined ? 'rgb(0, 180, 255)': color} 100%)` 
        }} 
 
        className="wrapperOrangeButton"
        onClick={onClick === undefined ? () => {} : () => onClick()}
      >
        <div style={{ ...styles }} className="textOrangeButton">{ text }</div>
      </div>
    );
}

export default OrangeButton;
