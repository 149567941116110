import ActionTypes from '../constants';
import moment from 'moment';
const initialState = {
  generatepage: {
    backgroundselect: 'none',
    photo: 'true',
    initialDeath:'true',
    epity: 'true',
    birthdate: moment().format("YYYY-MM-DD HH:mm"),
    deathdate: moment().format("YYYY-MM-DD HH:mm"),
    birthlocation:'Не указано', 
    deathlocation:'Не указано', 
    career:'Не указано', 
    children:'Не указано', 
    education:'Не указано', 
    nationality: 'Не указано',  
    secondhalf: 'Не указано', 
    pagelink: 'http://inmemory-forever.com/pages/0/#toppage',
    media:  JSON.stringify([]),
    OneBlockArea: "",
    OneBlockInputTitle: "",
    OneBlockOneInput: "",
    OneBlockTwoInput: "",
    ThreeBlockArea: "",   
    TwoBlockArea: "",
    TwoBlockInputTitle: "",
    TwoBlockOneInput: "",
    TwoBlockTwoInput: "", 
    fileOneList : "[]", 
    fileTwoList : "[]" 
  },
};

// const initialState = {
//   generatepage: {
//     backgroundselect: 'none',
//     photo: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/example.jpeg',
//     initialDeath:'!ФЕДОТОВ ВЛАДИМИР АЛЕКСЕЕВИЧ',
//     epity:`
//       Ветер в окна задувает, Сушит мокрые ресницы. Как тебя нам не хватает! На плече твоём забыться... 
//       Невозвратная потеря, Словно душу надломили... 
//       До сих пор ещё не верю, Что ты где-то в звёздной пыли. Задувает в окна ветер, От тебя он прилетает.
//     `,
//     birthdate:'1931-02-27',
//     deathdate:'2020-06-09',
//     birthlocation:'Россия', 
//     deathlocation:'Россия', 
//     career:'Учитель',
//     children:'есть',
//     education:'Высшее',
//     nationality: 'Русский', 
//     secondhalf: 'Федотова Наталья Паловна', 
//     pagelink: 'http://inmemory-forever.com/pages/0/#toppage',
    // media:  JSON.stringify(   [ 
    //   {
    //     original: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_0.jpeg',
    //     thumbnail: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_0.jpeg',
    //     originalWidth: '100%',
    //     originalHeight: '100%', 
    //   },
    //   {
    //     original: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_1.jpeg',
    //     thumbnail: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_1.jpeg',
    //     originalWidth: '100%',
    //     originalHeight: '100%', 
    //   },
    //   {
    //     original: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_2.webp',
    //     thumbnail: 'https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/examplefamily_2.webp',
    //     originalWidth: '100%',
    //     originalHeight: '100%', 
    //   },
    // ]),
//     OneBlockArea: "Тут будет описания биографии первый блок",
//     OneBlockInputTitle: "тайтл",
//     OneBlockOneInput: "Тут будет описания фотографии первый блок",
//     OneBlockTwoInput: "Тут будет описания фотографии первый блок",
//     ThreeBlockArea: "Тут будет описания биографии первый блок",   
//     TwoBlockArea: "Тут будет описания биографии первый блок",
//     TwoBlockInputTitle: "тайтл",
//     TwoBlockOneInput: "Тут будет описания фотографии первый блок",
//     TwoBlockTwoInput: "Тут будет описания фотографии первый блок", 
//     fileOneList : "[\"https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/example.jpeg\"]", 
//     fileTwoList : "[\"https://s3.timeweb.com/430133e2-5ac8175c-9526-49bf-adaa-a31193a1248c/example/example.jpeg\"]" 
//   },
// };

export default function generatepages(state = initialState, { type, payload }) {
 
  switch (type) {
    case ActionTypes.PAGEINFO_GENERATE_PAGE:
      return {
        ...state,
        generatepage: payload
      }; 
    default:
      return state;
  }
}