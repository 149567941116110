import React from 'react'; 
import { Empty } from 'antd';
import Moment from "react-moment";
import OrangeButton from '../OrangeButton'; 
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import { change_visible_popup } from '../../redux/actions/users'
import { setEditPage } from '../../redux/actions/pageinfo'
import './style.css';

export default function MiniPageBlock(props) {
  const { mobile, photo, fio, payinfo, pageId, nopage, birthdate, deathdate, disabled, onDeleted} = props;

  Moment.globalLocale = 'RU'; 
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  return (
    <div className={`${mobile ? 'mobileMiniPageBlockContainer' : 'miniPageBlockContainer'}`}>
      {
        !nopage &&  <>
        <div className={`${disabled ? 'disabled_cart' : ''}`}></div>
        <div className={`${mobile ? 'mobileTopMiniPageBlock' : 'topMiniPageBlock'}`}>
          <div className={`${mobile ? 'mobileTitleTopMiniPage' : 'titleTopMiniPage'}`}>{`${payinfo == "2" ? 'Полная' : 'Краткая'} страница`}</div>
          <div className='deletedContainer'>
            <div className='delclickc' id={pageId} onClick={(e) => {  
              onDeleted(e.target.id) 
            }}></div>
            <img className={`${mobile ? 'mobiledelimg' : 'delimg'}`} src={images.deleted} alt="delimg" />
          </div>
        </div>
        <div className='bottomMiniPageBlock'>
          <div className={`${mobile ? 'mobilecenterMiniBlock' : 'centerMiniBlock'}`}>
            <div className='topCenterMiniBlock'>
              <div className='topCenterImgBlock'>
                <img src={photo} alt="photoim" />
              </div>
              <div className='topCenterDateBlock'>
                <div className={`${mobile ? 'mobiletopTitleBlock' : 'topTitleBlock'}`}>{fio === '' ? 'Имя не указано' : fio}</div>
                <div className='topDataBlock'>
                  {
                    (deathdate !== undefined && birthdate !== undefined) && <>
                    {/* (birthdate !== 'Invalid date' && deathdate !== 'Invalid date' && deathdate !== undefined && birthdate !== undefined) && <> */}
                      <Moment format="YYYY" withTitle>{birthdate}</Moment>
                      <div style={{margin: '0px 15px'}}>-</div>
                      <Moment format="YYYY" withTitle>{deathdate}</Moment>
                    </>
                  } 
                  {
                    (deathdate === undefined || birthdate === undefined) && <>
                    {/* (birthdate === 'Invalid date' || deathdate === 'Invalid date' || deathdate === undefined || birthdate === undefined) && <> */}
                      Дата отсутствует
                    </>
                  } 
                </div>
              </div>
            </div>
            <div className='bottomCenterMiniBlock'>
              <div className={`${mobile ? 'mobilebottomBtnBlock' : 'bottomBtnBlock'}`}>
                <OrangeButton  styles={{ fontSize:mobile ? '12px' : '15px' }} height={mobile ? 36 : 40} width={mobile ? 130 : 180} text="Редактировать" onClick={() => { navigate(`/editpage/${pageId}`); }} />
                <OrangeButton margin={'0px 0px 0px 0px'} styles={{ fontSize:mobile ? '13px' : '16px' }} height={mobile ? 36 : 40} width={mobile ? 100 : 120} text="Смотреть" onClick={() => {navigate(`/pages/${pageId}`)}} />
              </div>
            </div>
          </div>
        </div>
      </>
      }
      {
        nopage &&  <> 
        <div className={`${mobile ? 'mobileEmptyMiniPageBlock' : 'emptyMiniPageBlock'}`}>
          <div className={`${mobile ? 'mobileEmptyCenterMiniBlock' : 'emptyCenterMiniBlock'}`}> 
            <Empty description={false} />
          </div> 
          <OrangeButton styles={{ fontSize:mobile ? '15px' : '20px' }} height={50} width={mobile ? 250 : 220} text="Создать страницу" onClick={() => { dispatch(change_visible_popup(true)); }} />
        </div>
      </>
      } 
    </div>
  );
}


//dispatch(setEditPage({ edit: true, pageId }));