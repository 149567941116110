import api from "../http";

export default class AuthServices {

  static async login(login,password) { 
      return api.main_api.post('/login', { login, password })
  }

  static async registration(login, password, registartion_tel) { 
      return api.main_api.post('/registration', { login, password, registartion_tel })
  }

  static async logout() {
      return api.main_api.post('/logout')
  }

  static async forgot(email) { 
      return api.main_api.post('/forgot',{ email })
  }

  static async createpage(data) {  
    
      return api.main_api.post('/createpage', data )
  }

  static async uploadphoto(photObj) {   
      return api.main_api.post('/uploadphoto', photObj )
  }

  static async editPage(page) {   
      return api.main_api.post('/editpage', page )
  }

  static async uploadmedia(media) {  
  
      return api.main_api.post('/uploadmedia', media )
  }

  static async getPageInfo(pageId) {   
      return api.main_api.post('/getPageInfo', { pageId } )
  }


  static async deletePage( pageId ) { 
    return api.main_api.post('/delete_page', { pageId })
  } 

 
  static async addMoney(email,obj) {
    return api.main_api.post('/addMoney',{ email,obj })
  }

  static async decMoney(email,sum) {
    return api.main_api.post('/decMoney',{ email, sum })
  }

  static async sendMoneyMail(email , id, sum, numbercard) {
    return api.main_api.post('/sendMoneyMail',{ email , id, sum, numbercard })
  }

  static async setOutMoney(email, obj) {
    return api.main_api.post('/setOutMoney',{ email, obj })
  }


}
