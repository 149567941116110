import React from 'react';
import './style.css';

export default function InputNumber(props) {

  const { styles, value, onChange, mobile, max} = props;

  return (
    <div style={styles} className={`${mobile ? 'mobile_input_number_component_wrapper' : 'input_number_component_wrapper'}`}>
      <input 
        type='number' 
        className='input_number_component_item' 
        onChange={(e) => { onChange(+e.target.value) }} 
        value={value} 
        min={0}
        max={max ? max : 100} 
      />
    </div>
  );
}