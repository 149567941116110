import ActionTypes from '../constants';
 
export function setMobileMod(mode) {
  return {
    type: ActionTypes.APP_MOBILE,
    payload: mode
  }
}

export function change_page(page) { 
  return {
    type: ActionTypes.APP_PAGES_SCREEN,
    payload: page
  }
} 

export function loader_switch(state) {
  return {
    type: ActionTypes.APP_LOADING,
    payload: state
  }
}
 
export function change_subpage(subpage) { 
  return {
    type: ActionTypes.APP_SUBPAGES_SCREEN,
    payload: subpage
  }
}

export function setSocket(socket) { 
  return {
    type: ActionTypes.APP_SOCKET,
    payload: socket
  }
}

export function setWorkDataUser(work_data_user) { 
  return {
    type: ActionTypes.APP_WORK_DATA_USER,
    payload: work_data_user
  }
}

export function setWorkDataRoom(work_data_room) { 
  return {
    type: ActionTypes.APP_WORK_DATA_ROOM,
    payload: work_data_room
  }
}
  