import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { slide as Menu } from 'react-burger-menu';
import { Link, useNavigate,useLocation } from 'react-router-dom'; 
import { header } from '../../redux/selectors';
import { change_page } from '../../redux/actions/app';
import { setPopupMainMsg } from '../../redux/actions/pageinfo';
import LkButton from '../LkButton';
import images from '../../assets/images';
import { change_visible_popup } from '../../redux/actions/users';
import './style.css';

function NavigateHeader(props) {

  const { statuy, cart, phone, map, time }= images;

  const { mobile, idPage, minitab, backg } = props; 
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activetab, setActiveTab] = useState('')
 
  const headers_tab = [
    {
      title: 'Краткая информация',
      key:"partinfo",
      rout: `/pages/${idPage}/#info`,
    },
    {
      title: 'Медиа файлы',
      key:"media",
      rout: `/pages/${idPage}/#medias`,
    },  
    {
      title: 'QR-код',
      key:"qr",
      rout: `/pages/${idPage}/#qrs`,
    } 
  ];
 
  const headers_minitab = [ 
    {
      title: 'Краткая информация',
      key:"partinfo",
      rout: `/pages/${idPage}/#info`,
    },
    {
      title: 'Биография',
      key:"bio",
      rout: `/pages/${idPage}/#bios`,
    },  
    {
      title: 'Медиа файлы',
      key:"media",
      rout: `/pages/${idPage}/#medias`,
    },  
    {
      title: 'QR-код',
      key:"qr",
      rout: `/pages/${idPage}/#qrs`,
    } 
  ];
 
  let st = {}
  let tabs = !minitab ? headers_tab : headers_minitab
 
    return (
      <div className={` ${ mobile ? 'mobileNavigateHeaderWrapper' : 'navigateHeaderWrapper' }`}>
        { mobile ? 
              <div className='tabsContainer'>   
              { 
              tabs.map(({ key, rout, title }) => {
                  st = key === activetab ? 'active' : ''
                  return <Link key={key} to={rout}><div id={key}  
                  style={ backg == '1' && backg != 'none' ? { color: '#fff' } : backg == '0' ||  backg == '2' ? { color: '#fff' } : { color: 'rgb(53, 61, 81)' }} 
                  className={`mobiele_navtab ${st}`} 
                  onClick={(e) => { /* dispatch(change_page(e.target.id));*/ }}>{title}</div></Link>
                })
              }   
            </div>  
          : 
          <div className='tabsContainer'>   
            { 
            tabs.map(({ key, rout, title }) => {
                st = key === activetab ? 'active' : ''
                return <Link key={key} to={rout}><div id={key}
                style={ backg == '1' && backg != 'none' ? { color: '#fff' } : backg == '0' ||  backg == '2' ? { color: '#fff' } : { color: 'rgb(53, 61, 81)' }} 
                 className={`navtab ${st}`} onClick={(e) => { /* dispatch(change_page(e.target.id));*/ }}>{title}</div></Link>
              })
            }   
          </div>  
        }
      </div>
    );
}

export default NavigateHeader;