 
import Main from './routes/index';
import { isMobile } from 'react-device-detect';
import React, { useLayoutEffect,useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change_page, setMobileMod } from './redux/actions/app';
import { checkAuth, set_restate, getUserInfo,set_user,set_user_isauth, getInfoUntilDays } from './redux/actions/users'
import { pages, users, loader,app, popup_login, popup_referal } from './redux/selectors';  
import Loader from './components/Loader'
import HeaderMenu from './components/HeaderMenu';
import PopapLogin from './components/PopapLogin';
import PopapReferal from './components/PopapReferal';

import './App.css';

  
function App() {
  const dispatch = useDispatch();  

  const page = useSelector(pages.page); 
  const mobile = useSelector(app.mobile); 
  const popup_visible = useSelector(popup_login.popup_visible);  
  const popup_referal_visible = useSelector(popup_referal.popup_visible);  
  const user = useSelector(users.user);
  const isAuth = useSelector(users.isAuth); 
  const loading = useSelector(loader.loading);

  const [loaddingdata,setLoadData] = useState(false)
  // const [width, setWidth] = useState(window.innerWidth);
 
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }

  useEffect(() => {    
    const fetchData = async () => {
      const user = await getUserInfo(dispatch) 
      if(user !== 401) { 
        dispatch(set_user_isauth(true));
        dispatch(set_user(user));  
      }   
      setLoadData(true) 
    }; 
    fetchData(); 
  },[loaddingdata]); 
 
  useEffect(() => {    
    if(localStorage.getItem('token_memory')) { 
      checkAuth(dispatch) 
    }  

    if(localStorage.getItem('page') === null) {  
      localStorage.setItem('page','main'); 
    }   

    dispatch(setMobileMod(isMobile));
    dispatch(change_page(localStorage.getItem('page')));
    // window.addEventListener('resize', handleWindowSizeChange);
    // return () => {
    //     window.removeEventListener('resize', handleWindowSizeChange);
    // }
  },[]);  
 
  return ( 
    <div className="App"> 
      { loading && <Loader text='Загрузка...' /> } 
      <HeaderMenu 
        mobile={mobile} 
        userName={user?.email == undefined ? '' : user.email}  
        page={page} 
        payinfo={user?.payinfo == undefined ? '0' : user.payinfo}
        partnerlink={user?.partnerLink == undefined ? '' : user.partnerLink}
        balance={user?.balance == undefined ? '' : user.balance}
        onClick={(e) => { 
          localStorage.setItem('page',e.target.id);
          dispatch(change_page(e.target.id))
        }} 
        isAuth={isAuth}  
      />
      { popup_visible && <PopapLogin mobile={mobile} userActivated={user?.isActivated} /> } 
      { popup_referal_visible && <PopapReferal userName={user?.login == undefined ? '' : user.login} mobile={mobile} balance={user?.balance == undefined ? '' : user.balance} /> } 
      <Main user={user} isAuth={isAuth} mobile={mobile} />
    </div>
  );
}

export default App;
