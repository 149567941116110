import { React, useState } from 'react';
import { Input, Radio } from 'antd'; 
import InputPopap from '../InputPopap'
import './style.css'; 
const { TextArea } = Input;
 
function BackGroundCreate(props) {
    const { background, onClick, id, checked, mobile } = props;

    return ( 
      <div className={`backgroundContainer`}> 
        <div className='backgroundItem' style={{backgroundImage: `url(${background})`}}></div> 
        <Radio checked={checked} value={id} onChange={(e) => onClick(e.target.value)}>{id === 3 ? `Без фона` :`Фон № ${id +1}`}</Radio>
      </div>
    );
}

export default BackGroundCreate;
