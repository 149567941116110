import React from 'react'; 
import OrangeButton from '../OrangeButton';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import { ordedr_work } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import './style.css';

export default function ExamplePageBlock(props) {
  const { mobile, id } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="examplePageBlockWrapper">
    {/* <div style={{height: '160px', width: '100%'}} /> */}
      {
        mobile ?
        <>
           <Title  mobile={mobile} size={mobile ? 35 : 15}  margin="50px 0px 0px 0px" text="Пример памятной страницы" under /> 
          <ExamplePage 
            mobile={mobile}
            photo={images.fon} 
            info={'АЛЕКСАНДР СЕРГЕЕВИЧ ПУШКИН'} 
            epity={`
О люди! Жалкий род, достойный слёз и смеха./n/nЖрецы минутного, поклонники успеха!/n/nКак часто мимо вас проходит человек,/n/nНад кем ругается слепой и буйный век,/n/nНо чей высокий лик в грядущем поколенье/n/nПоэта приведёт в восторг и умиленье!
            `} 
          />  
          <div className="examplePageBlockBtnWrapper">
            <OrangeButton id={id} styles={{ fontSize: '18px' }} height={50} width={320} margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }} text="Подробнее" onClick={() => { navigate(`/pages/0/#toppage`); }} />
          </div>
          </> :
        <>
          <Title margin="50px 0px 0px 0px" text="Пример памятной страницы" under /> 
          <ExamplePage 
            photo={images.fon} 
            info={'АЛЕКСАНДР СЕРГЕЕВИЧ ПУШКИН'} 
            epity={`
О люди! Жалкий род, достойный слёз и смеха./n/nЖрецы минутного, поклонники успеха!/n/nКак часто мимо вас проходит человек,/n/nНад кем ругается слепой и буйный век,/n/nНо чей высокий лик в грядущем поколенье/n/nПоэта приведёт в восторг и умиленье!
            `} 
          />  
          <div  className="examplePageBlockBtnWrapper">
            <OrangeButton id={id} height={55} width={350} margin={{ width: mobile ? "0px 0px 0px 0px" : "20px 0px 0px 0px" }} text="Подробнее" onClick={() => { navigate(`/pages/0/#toppage`); }} />
          </div>
        </>
      }
    </div>
  );
}
