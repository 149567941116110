import React from 'react'; 
import OrangeButton from '../OrangeButton';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import { ordedr_work } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import './style.css';

export default function PageInfoBlock(props) {
  const { birthlocation, deathlocation, career, children, education, nationality, secondhalf, mobile, transparent,backg } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div style={transparent ? { background: 'transparent' } : {} } className="pageInfoBlockWrapper">
      {
        mobile ?
        <>
          <Title  color={ backg != 'none' ? '#fff': undefined } 
            size={30} 
            margin="50px 0px 0px 0px" 
            text="Краткая информация" 
            under 
          /> 
          <div  className={`${mobile ? 'mobilePageInfoContainer' : 'pageInfoContainer'} colorFon_${backg}`}> 
            <div className={`${mobile ? 'mobilePageInfoCol' : 'pageInfoCol'} `}>
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Место рождения</div>
                <div className='pageInfoColSectionDesc'>{ birthlocation }</div>
              </div> 
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Место смерти</div>
                <div className='pageInfoColSectionDesc'>{ deathlocation }</div>
              </div> 
            </div>
            <div className={`${mobile ? 'mobilePageInfoCol' : 'pageInfoCol'}`}>
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Гражданство</div>
                <div className='pageInfoColSectionDesc'>{ nationality }</div>
              </div> 
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Образование</div>
                <div className='pageInfoColSectionDesc'>{ education }</div>
              </div> 
            </div>
            <div className={`${mobile ? 'mobilePageInfoCol' : 'pageInfoCol'}`}>
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Супруга</div>
                <div className='pageInfoColSectionDesc'>{ secondhalf }</div>
              </div> 
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Дети</div>
                <div className='pageInfoColSectionDesc'>{ children }</div>
              </div> 
            </div>
            <div className={`${mobile ? 'mobilePageInfoCol' : 'pageInfoCol'}`}>
              <div className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'} colorFon_${backg}`}>Род деятельности</div>
                <div className='pageInfoColSectionDesc'>{ career }</div>
              </div> 
              <div  className={`${mobile ? 'mobilePageInfoColSection' : 'pageInfoColSection'}`}>
                <div className={`${mobile ? 'mobilePageInfoColSectionTitle' : 'pageInfoColSectionTitle'}`}></div>
                <div className='pageInfoColSectionDesc'></div>
              </div> 
            </div>
          </div>
          </> :
        <>
          <Title  color={ backg != 'none' ? '#fff': undefined } margin="50px 0px 0px 0px" text="Краткая информация" under /> 
          <div  className={`${mobile ? 'mobilePageInfoContainer' : 'pageInfoContainer'} colorFon_${backg}`}>
            <div className='pageInfoCol'>
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Место рождения</div>
                <div className='pageInfoColSectionDesc'>{ birthlocation }</div>
              </div> 
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Место смерти</div>
                <div className='pageInfoColSectionDesc'>{ deathlocation }</div>
              </div> 
            </div>
            <div className='pageInfoCol'>
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Гражданство</div>
                <div className='pageInfoColSectionDesc'>{ nationality }</div>
              </div> 
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Образование</div>
                <div className='pageInfoColSectionDesc'>{ education }</div>
              </div> 
            </div>
            <div className='pageInfoCol'>
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Супруга</div>
                <div className='pageInfoColSectionDesc'>{ secondhalf }</div>
              </div> 
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Дети</div>
                <div className='pageInfoColSectionDesc'>{ children }</div>
              </div> 
            </div>
            <div className='pageInfoCol'>
              <div className='pageInfoColSection'>
                <div className={`colorFon_${backg} pageInfoColSectionTitle`}>Род деятельности</div>
                <div className='pageInfoColSectionDesc'>{ career }</div>
              </div> 
              <div  className='pageInfoColSection'>
                <div className='pageInfoColSectionTitle'></div>
                <div className='pageInfoColSectionDesc'></div>
              </div> 
            </div>
          </div>
        </>
      }
    </div>
  );
}
