import { combineReducers } from 'redux';
import header from './header'
import app from './app'
import pageinfo from './pageinfo'
import pages from './pages'
import popup_login from './popup_login'
import popup_referal from './popup_referal'
import users from './users'
import loader from './loader'
import generatepages from './generatepages'

const rootReducer = combineReducers({
  header,
  app,
  pageinfo,
  popup_login,
  popup_referal,
  users,
  generatepages,
  pages,
  loader
});

export default rootReducer;