import statuy from './images/statuy.webp';
import cart from './images/cart.png';  
import cross from './images/cross.svg'; 
import fon from './images/fon.png'; 
import arrowl from './images/arrowl.png';
import arrowr from './images/arrowr.png';
import starfill from './images/starfill.png';
import star from './images/star.png';
import profile from './images/profile.png'; 
import visa from './images/visa.svg';
import mastercard from './images/mastercard.svg'; 

import action_1 from './images/action_1.png';
import action_2 from './images/action_2.png';
import action_3 from './images/action_3.png';

import fon_0 from './images/fon_0.png';
// import fon_0 from './images/fon_0.jpeg';
import fon_1 from './images/fon_1.png';
// import fon_1 from './images/fon_1.jpeg';
import fon_2 from './images/fon_2.png'; 
// import fon_2 from './images/fon_2.jpeg'; 

import memory from './images/memory.png';
import memory_2 from './images/memory_2.png';

import qr from './images/qr.png';
import logoqr from './images/logoqr.png';
import exit from './images/exit.png';

import quotl from './images/quotleft.png';
import quotr from './images/quotright.png'; 

import linelivegray from './images/line_live_gray.svg';
import linedeadgray from './images/line_dead_gray.svg'; 

import linelive from './images/line_live.svg';
import linedead from './images/line_dead.svg'; 
import deleted from './images/delete.png';

import arrowdown from './images/arrow_down.png';

import examplephoto from './images/example.jpeg'; 
export default {
  exit,
  examplephoto,
  deleted,
  linelive,
  linedead,
  quotl,
  quotr,
  action_1,
  action_2,
  action_3,
  logoqr,
  memory_2,
  memory,
  qr,
  statuy, 
  cart, 
  cross, 
  fon, 
  arrowl,
  arrowr,
  starfill,
  star,
  profile, 
  mastercard, 
  visa,
  fon_0,
  fon_1,
  fon_2,
  linelivegray,
  linedeadgray,
  arrowdown
}
