import React, { useState } from 'react'; 
import OrangeButton from '../OrangeButton';
import { Button, QRCode } from 'antd';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import { ordedr_work } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { change_visible_popup } from '../../redux/actions/users' 
import moment from 'moment';
import { 
  setGeneratePage,
  setDeathDate,
  setBirthdayDate,
  setInitialDeath,
  setNationality,
  setBirthLocation,
  setDeathLocation,
  setEpity,
  setChildren,
  setSecondHalf,
  setCareer,
  setEducation,
  sendEmailServer, 
  setFile, 
  setBackgroundSelect,  
  setOneBlockArea, 
  setOneBlockInputTitle,
  setOneBlockOneInput,
  setOneBlockTwoInput,
  setTwoBlockArea, 
  setTwoBlockInputTitle,
  setTwoBlockOneInput,
  setTwoBlockTwoInput,
  setThreeBlockArea, 
  setRegestratorTel, 
  setEditPage  
}  from '../../redux/actions/pageinfo'; 
import imageToBase64 from 'image-to-base64/browser';
import {
  ShareAltOutlined
} from '@ant-design/icons';
import { RWebShare } from "react-web-share";
import { users } from '../../redux/selectors'; 
import './style.css';

export default function PageQrBlock(props) {
  const { mobile, pageLink, transparent, backg } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [urls,setUrl] = useState('')

  const user = useSelector(users.user);


  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

   
  return (
    <div id="qrs" style={transparent ? { background: 'transparent' } : {} } className="pageQrBlockWrapper">
      {
        mobile ?
        <>
              <Title color={transparent ? '#fff' : undefined} size={30} margin="50px 0px 0px 0px" text="QR-код для страницы" under /> 
          <div  id="myqrcode" className={`${mobile ? 'mobilePageQrContainer' : 'pageQrContainer'}`}> 
          <div className='qrTitle'>inmemory-forever.com</div>
            <QRCode
              value={ pageLink }
	      bgColor="white"              
	      style={{
                marginBottom: 16,
              }}
            />
            <div className='btnMobileContainer'>
              <Button type="primary" onClick={downloadQRCode}>
                Скачать
              </Button> 
              <RWebShare
                data={{
                  text: "Сервис памяти о близких, с вами поделились страницей: ",
                  url: pageLink,
                  title: "Поделиться c близкими: ",
                }}
                onClick={() => {
           
                  // const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
                  // if (canvas) {
                  //   const url = canvas.toDataURL(); 
                  //   setUrl(url)
                  // }
                }}
              >
              <Button style={{ marginLeft: '15px' }} type="primary" onClick={() => { 
          
              }}>
               <ShareAltOutlined />
              </Button>  
              </RWebShare> 
            </div> 
          </div>
          </> :
        <>
          <Title color={backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined}   size={50} margin="50px 0px 0px 0px" text="QR-код для страницы" under /> 
          <div  id="myqrcode" className={`${mobile ? 'mobilePageQrContainer' : 'pageQrContainer'}`}> 
          <div className='qrTitle'>inmemory-forever.com</div>
            <QRCode
             value={ pageLink }
	     bgColor="white"            
  	     style={{
                marginBottom: 16,
              }}
            />
             <div className='btnMobileContainer'>
              <Button type="primary" onClick={downloadQRCode}>
                Скачать
              </Button> 
              <RWebShare
                data={{
                  text: "Сервис памяти о близких, с вами поделились страницей: ",
                  url: pageLink,
                  title: "Поделиться c близкими: ",
                }}
                onClick={() => {
                
                  // const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
                  // if (canvas) {
                  //   const url = canvas.toDataURL(); 
                  //   setUrl(url)
                  // }
                }}
              >
              <Button style={{ marginLeft: '15px' }} type="primary" onClick={() => { 
              
              }}>
               <ShareAltOutlined />
              </Button>  
              </RWebShare> 
            </div>
          </div>
        </>
      }
              <OrangeButton
          styles={{ fontSize: '14px' }}
          height={mobile ? 40 : 55}
          width={mobile ? 300 : 350}
          margin={ mobile ? "0px 0px 50px 0px" : "20px 0px 50px 0px" }
          text="Создать страницу памяти близкому"
          onClick={() => {  
     
            if( user.payinfo === '2') {
                navigate('/createpage/#uppage');  
                dispatch(setOneBlockArea('')) 
                dispatch(setOneBlockInputTitle('')) 
                dispatch(setOneBlockOneInput(''))
                dispatch(setOneBlockTwoInput(''))
                dispatch(setTwoBlockArea('')) 
                dispatch(setTwoBlockInputTitle('')) 
                dispatch(setTwoBlockOneInput(''))
                dispatch(setTwoBlockTwoInput(''))
                dispatch(setThreeBlockArea(''))    
                dispatch(setDeathDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setBirthdayDate(moment().format("YYYY-MM-DD HH:mm")));
                dispatch(setInitialDeath(''));
                dispatch(setNationality(''));
                dispatch(setBirthLocation(''));
                dispatch(setDeathLocation(''));
                dispatch(setEpity(''));
                dispatch(setFile([])); 
                dispatch(setChildren(''));
                dispatch(setSecondHalf(''));
                dispatch(setCareer('')); 
                dispatch(setEducation(''));
                dispatch(setBackgroundSelect(3));  
            } else {
              dispatch(change_visible_popup(true)); 
            }  
          }} />
    </div>
  );
}
