 
import React, { useEffect, useState } from 'react'; 
import NavigateHeader from '../../components/NavigateHeader';  
import PageTitleBlock from '../../components/PageTitleBlock';  
import PageBioBlock from '../../components/PageBioBlock';  
import PageInfoBlock from '../../components/PageInfoBlock';  
import PageLocationBlock from '../../components/PageLocationBlock';  
import PageQrBlock from '../../components/PageQrBlock';  
import PagePhotoBlock from '../../components/PagePhotoBlock';  
import PageVideoBlock from '../../components/PageVideoBlock';  
import PageAudioBlock from '../../components/PageAudioBlock';  
import OrangeButton from '../../components/OrangeButton';  
import images from '../../assets/images';  
import { generatepages, users } from '../../redux/selectors';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPageInfo } from '../../redux/actions/users' 

import { 
  setGeneratePage, 
}  from '../../redux/actions/pageinfo';  
import './style.css'; 

function PageScreen(props) {
 
  const { mobile } = props; 
  const { profile } = images; 
  const { idPage } = useParams() 
  const dispatch = useDispatch();  
 
  useEffect(() => {    
    const fetchData = async () => {
      const page = await getPageInfo(idPage,dispatch) 
  //  console.log(page.data)
      if(page.status == 200) {   
        dispatch(setGeneratePage({ ...page.data }));   
      }  
    }; 
    fetchData();  

  },[]);
 

  const generatepage = useSelector(generatepages.generatepage);
  const user = useSelector(users.user);

  var backg = 'none';

  if(generatepage?.backgroundselect !== 'none') {  
    backg = generatepage?.backgroundselect.split('/')[3].split('.')[0].split('_')[1]; 
  }
 
  function inphoPhotoFilter(photoArr,descrArr) {
 
    let tempArr = []; 
    if(photoArr != null) {
      photoArr.map((item,key) => {
        tempArr.push({
          img: item,
          titlephoto: descrArr[key]
        })
      })
    }
    return tempArr;
  }
   
  function itemBioArr(bioArr) {

    let tempArr = []; 

    bioArr.map((item,key) => { 

      if(item[0] !== '' && item[1].length !== 0 && item[4] === '') { 
 
        tempArr.push({
          title: item[0],
          infoPhoto: inphoPhotoFilter(JSON.parse(item[1]), [item[2],item[3]]),
          description: item[4], 
        })
      } else if (item[4] !== '') {  
        tempArr.push({
          title: item[0],
          infoPhoto: inphoPhotoFilter(JSON.parse(item[1]), [item[2],item[3]]),
          description: item[4], 
        })
      }

    })
  
    return tempArr;
  }
  
  let biosection = itemBioArr([[generatepage?.OneBlockInputTitle, generatepage?.fileOneList, generatepage?.OneBlockOneInput, generatepage?.OneBlockTwoInput, generatepage?.OneBlockArea], 
    [generatepage?.TwoBlockInputTitle, generatepage?.fileTwoList, generatepage?.TwoBlockOneInput, generatepage?.TwoBlockTwoInput, generatepage?.TwoBlockArea], 
    ['', JSON.stringify([]) ,'' , '' , generatepage?.ThreeBlockArea]
  ])
 
 
   
  return (
    <div className="page_screen"> 
      <div style={{ backgroundImage: `url(${generatepage.backgroundselect})` }} className={` ${ mobile ? 'mobile_page_wrapper' : 'page_wrapper' }`}>
        <div id="toppage" style={{height: '180px', width: '100%'}} />
        <NavigateHeader backg={backg} minitab={user.payinfo == '2'} mobile={mobile} idPage={idPage} /> 
        <PageTitleBlock 
          mobile={mobile}
          backg={backg}
          transparent={ generatepage.backgroundselect !== 'none' }
          photo={generatepage.photo !== null ? generatepage.photo : profile}
          info={ generatepage.initialDeath === '' ? 'Имя не указано' : generatepage.initialDeath }
          epity={ generatepage.epity === '' ? 'Не указано' : generatepage.epity }
          birthdate = { generatepage.birthdate }
          deathdate = { generatepage.deathdate } 
        /> 
        <PageInfoBlock 
          mobile={mobile}
          backg={backg}
          transparent={ generatepage.backgroundselect !== 'none' }
          birthlocation={ generatepage.birthlocation === '' ? 'Не указано' : generatepage.birthlocation } 
          deathlocation={ generatepage.deathlocation === '' ? 'Не указано' : generatepage.deathlocation } 
          career={ generatepage.career === '' ? 'Не указано' : generatepage.career } 
          children={ generatepage.children === '' ? 'Не указано' : generatepage.children } 
          education={ generatepage.education === '' ? 'Не указано' : generatepage.education } 
          nationality={ generatepage.nationality === '' ? 'Не указано' : generatepage.nationality } 
          secondhalf={ generatepage.secondhalf === '' ? 'Не указано' : generatepage.secondhalf } 
        /> 
        {/* {
          user.payinfo == '2' && <PageBioBlock mobile={mobile} biosection={biosection} id="bios" /> 
        } */}
        <PageBioBlock backg={backg} mobile={mobile} transparent={ generatepage.backgroundselect !== 'none' } biosection={biosection} id="bios" /> 
        <PagePhotoBlock backg={backg} transparent={ generatepage.backgroundselect !== 'none' } mobile={mobile} media={generatepage.media === undefined ? JSON.stringify([]) : generatepage.media} /> 
        <PageQrBlock backg={backg} mobile={mobile} pageLink={generatepage.pagelink} transparent={ generatepage.backgroundselect !== 'none' }  /> 

        {/* <PageVideoBlock />  */}
        {/* <PageAudioBlock />  */}
        {/* <PageLocationBlock id="location" />  */} 
    
      </div>
    </div>
  );
}

export default PageScreen;
 