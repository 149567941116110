import React from 'react';
import DataLine from '../DataLine';
import images from '../../assets/images';
import './style.css';

function ExamplePage(props) {
    const { photo, info, epity, mobile } = props;
    const { examplephoto,  quotl, quotr, } = images;

    return (
      <div className={`${mobile ? 'mobileExamplePageContainer' : 'examplePageContainer'}`}>
        <div className={`${mobile ? 'mobilePhotoContainer' : 'photoContainer'}`}>
          <img className='photo' src={examplephoto} alt="photos" />
        </div>
        <div className={`${mobile ? 'mobileFioinfo' : 'fioinfo'}`}>{info}</div>
        <div className='epityContainer'>
          <img className={`${mobile ? 'mobileQuotl' : 'quotr'}`} src={quotl} alt="quotl" />
          <div className={`${mobile ? 'mobileEpity' : 'epity'}`}>{`${epity.replace (/\/n\/n/gm,`\n`)}`}</div>
          <img className={`${mobile ? 'mobileQuotr' : 'quotl'}`} src={quotr} alt="quotr" />
        </div>
        <DataLine backg='none' mobile={mobile} birth='1799-06-06' death='1837-02-10' />
      </div>
    );
}

export default ExamplePage;
