import React, { useState } from 'react';
import './style.css';
import images from '../../assets/images'; 

export default function Answer(props) {

  const { title, description, mobile, id } = props;
  const { cross } = images; 
  const [isToggleOn,setIstoogle] =useState(true);
  return (
    <div className="answer_container">
    <div id={id} style={mobile ? { paddingLeft: '6%'} : {} } className="answer_cross">
      <img onClick={() => setIstoogle(!isToggleOn)} className={`${mobile ? 'mobileCross_rotate' : 'cross_rotate' } ${!isToggleOn ? 'on_crooss' : 'off_crooss' }`} src={cross} alt="logo" width="15" height="15" />
      <div className={`${mobile ? 'mobileAnswerTitle' : "answer_title"}`} onClick={() => setIstoogle(!isToggleOn)}>{title}</div>
    </div>
    {!isToggleOn ? <div className={`${mobile ? 'mobileAnswer_description' : 'answer_description' }`} dangerouslySetInnerHTML={{__html: description}} /> : ''}
    </div>
  );
}
 