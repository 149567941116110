import React from 'react'; 
import OrangeButton from '../OrangeButton';
import ExamplePage from '../ExamplePage';
import Title from '../Title';
import DataLine from '../DataLine';  
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
import { ordedr_work } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';  
import images from '../../assets/images';
import './style.css';

export default function PageTitleBlock(props) {
   
  const { photo, info, epity,birthdate, deathdate, mobile, transparent,backg } = props;
  const { quotl, quotr, memory_2 } = images;

  const navigate = useNavigate();
  const dispatch = useDispatch();



  return (
    <div  style={transparent ? { background: 'transparent' } : {} } className="pageTitleBlockWrapper">
      {
        mobile ?
        <>
          <div className='pageTitleBlockContainer'>
            {
              (birthdate !== '' && deathdate !== '') && <DataLine backg={backg} mobile={mobile} transparent={transparent} birth={birthdate} death={deathdate} />
            }  
            {
              (birthdate === '' || deathdate === '') && <div className={`colorFon_${backg} errorDateTxt`}>Дата указана не верно</div> 
            }
            <div  className={`${mobile ? 'mobilePhotoContainer' : 'photoContainer'}`}>
            {
              photo == 'true' ? <Skeleton.Image active={true} /> : <img className='photo' src={photo} alt="photos" />
            }
            </div> 
            {
              info == 'true' ?  <Skeleton.Input active={true} /> : <div className={`${mobile ? 'mobileFioinfo' : 'fioinfo' } colorFon_${backg}`}>{info}</div>
            } 
            <div className='epityContainer'>
              <img className={`${mobile ? 'mobileQuotl' : 'quotr'}`} src={quotl} alt="quotl" />
              {
                epity == 'true' ?  <div className={`${mobile ? 'mobileEpity' : 'epity'} colorFon_${backg}`} ><Skeleton.Input active={true} /></div> :  <div className={`${mobile ? 'mobileEpity' : 'epity'} colorFon_${backg}`}>{`${epity.replace (/\/n\/n/gm,`\n`)}`}</div>
              } 
              <img className={`${mobile ? 'mobileQuotr' : 'quotl'}`} src={quotr} alt="quotr" />
              <div style={{ width:'10px', height:'10px', position:'absolute', bottom:'85px', left:'40px' }} id="info"/>
            </div> 
          </div>
        </> :
        <>
            <div>
            {
              (birthdate !== '' && deathdate !== '') && <DataLine backg={backg} transparent={transparent} birth={birthdate} death={deathdate} />
            }  
            {
              (birthdate === '' || deathdate === '') && <div className='errorDateTxt'>Дата указана не верно</div> 
            }
            <div className='pageTitlePhotoContainer'>
            {
              photo == 'true' ? <Skeleton.Image active={true} /> : <img className='pageTitlePhoto' src={photo} alt="photos" />
            } 
            </div>
            {
              info == 'true' ? <div className={`pageTitleFio colorFon_${backg}`}> <Skeleton.Input active={true} /></div> : <div className={`pageTitleFio colorFon_${backg}`}>{info}</div>
            } 
            <div className='pageTitleEpityContainer'>
              <img className='pageTitleQuotr' src={quotl} alt="quotl" />
              {
                epity == 'true' ?  <div className={`pageTitleEpity colorFon_${backg}`} ><Skeleton.Input active={true} /></div> :   <div className={`colorFon_${backg} pageTitleEpity`}>{`${epity.replace (/\/n\/n/gm,`\n`)}`}</div>
              } 
             
              <img id="info" className='pageTitleQuotl' src={quotr} alt="quotr" />
            </div>
          </div>
        </>
      }
    </div>
  );
}
