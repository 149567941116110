import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { slide as Menu } from 'react-burger-menu';
import { Button, Divider, Popover, Segmented, QRCode } from 'antd'; 
import { Link, useNavigate,useLocation } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import { header } from '../../redux/selectors';
import { UnlockOutlined, LockOutlined, UserOutlined,ShareAltOutlined } from '@ant-design/icons';
import { change_page } from '../../redux/actions/app';
// import { setPopupMainMsg } from '../../redux/actions/pageinfo';
import LkButton from '../../components/LkButton';
import OrangeButton from '../../components/OrangeButton';
import Answer from '../../components/Answer';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import images from '../../assets/images';  
import { RWebShare } from "react-web-share";
import { change_visible_popover, change_visible_popup,change_visible_referal_popup, logout, set_user_isauth } from '../../redux/actions/users';
import './style.css'; 
function HeaderMenu(props) {
  const { arrowdown }= images;
  const { onClick, userName, page, mobile, isAuth, payinfo, partnerlink, balance } = props;
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {   
  
      // test.style.width = mobile ? '100%' : '430px' 

    // dispatch(change_page(localStorage.getItem('page'))); 
    // dispatch(setPopupMainMsg(''));
  },[page]); 

  

  const {hash, key} = useLocation();
const open = useSelector(header.open)
const [isToggleOn,setIstoogle] = useState(true);
const [isToggleMenu,setIstoogleMenu] = useState(false);
 
  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcodes')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(()=>{
      if(hash){
        const targetElement = document.getElementById(hash.substring(1))
        targetElement?.scrollIntoView({behavior: 'smooth'})
      }
  }, [key, hash])

  const headers_tab = [
    {
      title: 'Главная',
      key:"main",
      rout: '/#toppage',
    },
    // {
    //   title: 'Цена',
    //   key:"price",
    //   rout: '/price',
    // },
    {
      title: 'Как работает',
      key:"works",
      rout: '/#howitworks',
    }, 
    {
      title: 'Пример страницы',
      key:"view",
      rout: '/#example',
    }, 
    {
      title: 'Вопросы',
      key:"questions",
      rout: '/#answer',
    },
    {
      title: 'Контакты',
      key:"contacts",
      rout: '/#answer',
    },
    {
      title: 'Отзывы',
      key:"feedbacks",
      rout: '/#feedback',
    },
  ];
 
  let st = {}
 

  const content = (
    <div>  

      <div className={`profiletab`} onClick={() => { dispatch(change_visible_popup(true));dispatch(change_visible_popover(false)) }}>{'Создать страницу'}</div>
      <Link to={'/mypages'}> <div  onClick={() => {dispatch(change_visible_popover(false))}}  className={`profiletab`}>{'Мои страницы'}</div></Link> 
      <div className="arrow_container">
        <img onClick={() => setIstoogle(!isToggleOn)} className={`arrow_rotate ${!isToggleOn ? 'on_arrow' : 'off_arrow' }`} src={arrowdown} alt="logo" width="15" height="15" />
        <div className={`${mobile ? 'mobileArrowTitle' : "arrow_title"}`} onClick={() => setIstoogle(!isToggleOn)}>{'Партнёрам'}</div>
      </div>
      <div className="arrowselect_container">

{!isToggleOn ? <div  id="myqrcodes" className={`refbal`} onClick={() => {  }}>  
      <div className='textbal'>Реферальный баланс: <span style={{color: '#026ec7'}}>{ balance }</span> руб.</div>
      <div className='textref'>
        <div>Реферальная ссылка: </div>
        <span style={{color: '#026ec7', textAlign: 'center',marginTop: '7px'}}>{`https://inmemory-forever.com/api/partners/${partnerlink}`}</span>
        </div> 
        {/* <CopyToClipboard text={`https://inmemory-forever.com/api/partners/${partnerlink}`}>
          <button className="wrapperOrangeButton textOrangeButton copyBtn" style={{ 
            fontSize: '16px',
            with: '200px', 
            height: '35px'
          }} >Скопировать url</button>
        </CopyToClipboard> */} 
      
          <QRCode
            value={`https://inmemory-forever.com/api/partners/${partnerlink}`}
            bgColor="white"
	    style={{
              marginBottom: 16,
            }}
          />
            <div className='qrbtncontainer'>
          <Button type="primary" onClick={downloadQRCode}> Скачать </Button> 
          <RWebShare
          data={{
            text: "Сервис памяти о близких, с вами поделились реферальной ссылкой: ",
            url: `https://inmemory-forever.com/api/partners/${partnerlink}`,
            title: "Поделиться c близкими: ",
          }}
          onClick={() => {
          
            // const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
            // if (canvas) {
            //   const url = canvas.toDataURL(); 
            //   setUrl(url)
            // }
          }}
        >
        <Button style={{ marginLeft: '15px' }} type="primary" onClick={() => { 
        
        }}>
          <ShareAltOutlined />
        </Button>  
        </RWebShare> 
        </div>
    
      <OrangeButton text='Запросить вывод' margin="10px 0px 10px 0px" styles={{fontSize: '16px'}} width={200} height={35} onClick={() => {dispatch(change_visible_referal_popup(true))}} disabled={ payinfo != 2 } />
</div> : ''}
</div>   
      <div className={`profiletab`} onClick={() => { dispatch(change_visible_popover(false));logout(dispatch);   dispatch(set_user_isauth(false)); navigate('/') }}>{'Выход'}</div>
    </div>
  );
  // 
    return (
      <div id={'main'} className={` ${ mobile ? 'mobileHeaderWrapper' : 'headerWrapper' }`}>
        { mobile ? 
          <>
            <div className='mobileTopHeader'>
            <Menu onOpen={ ()=>{ setIstoogleMenu(!isToggleMenu); dispatch(change_visible_popover(false)) } } onClose={ ()=>{setIstoogleMenu(!isToggleMenu)} } isOpen={ isToggleMenu } >
              { headers_tab.map(({ key, rout, title }) => {
                st = key === page ? 'active' : ''
                return <Link key={key} to={rout}><div id={key} className={`tabMobile ${st}`} onClick={(e) => { onClick(e); }}>{title}</div></Link>
              })} 
              {/* (<Link key={i} className={`tabMobile ${tab === i ? 'active' : ''}`} to={item.to}><div id={i} onClick={(e) => { dispatch(setTabHeader(Number(e.target.id))) }} >{item.title}</div></Link>))
              }   */}
            </Menu>
            </div>
           
            <div className='mobileBottomHeader'>
            <div className='mobileLableContainer'>
              <div className='mobiletextLogoContainer'>
                <div className="mobileLogo">Вечная память</div>
                <div className="subLogo">Электронная память навсегда</div>
              </div>
              {/* <img style={{marginLeft: '15px' }} src={'statuy'} alt="profile" width="40" height="40" /> */}
              {
                isAuth ? 
                <> 
                  <Popover placement="bottom" title={userName} content={content} open={open} >
                    <Button onClick={() => { dispatch(change_visible_popover(!open))}} className="profilebtn" size={'large'} style={{ marginLeft: '13px', width: '50px', height: '50px', color:'#fff'}} ghost icon={<UnlockOutlined />} /> 
                  </Popover> 
                </> 
                : 
                  <Button onClick={() => { dispatch(change_visible_popup(true))}} className="profilebtn" size={'large'} style={{ marginLeft: '13px', width: '50px', height: '50px', color:'#fff'}} ghost icon={<LockOutlined />} /> 
            
              }  
            </div> 
            </div>
          </>
          :
          <>
            <div className='bottomHeader'>
            <div className='lableContainer'>
              <div className='textLogoContainer'>
                <div className="logo">Вечная память</div>
                <div className="subLogo">Электронная память навсегда</div>
              </div>
              {/* <img style={{marginLeft: '15px' }} src={statuy} alt="profile" width="40" height="40" /> */}
            </div>
            <div className="catigories">
            { headers_tab.map(({ key, rout, title }) => {
                st = key === page ? 'active' : ''
                return <Link key={key} to={rout}><div style={{ minWidth: key == 'works' ? '140px' : key == 'view' ? '185px' : '0px' }} id={key} className={`tab ${st}`} onClick={(e) => { onClick(e); }}>{title}</div></Link>
              })} 
            </div>  
              {
                isAuth ? 
                <> 
                  <Popover titleMinWidth={300} placement="bottom" title={userName} content={content} >
                    <Button className="profilebtn" size={'large'} style={{ marginRight: '43px', width: '130px', height: '60px', color:'#fff'}} ghost icon={<UserOutlined />} /> 
                  </Popover> 
                </> 
                :
                <LkButton onClick={() => dispatch(change_visible_popup(true))} margin='0px 50px 0px 0px' width={230} height={45} text="Личный кабинет" />
              }  
            </div> 
          
          </> 
        }
      </div>
    );
}

export default HeaderMenu;
