import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
 
import FooterLinkButton from '../FooterLinkButton';
import './style.css';

function Footer(props) {
  const { visa, mastercard } = images;
  const { mobile,id } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
    return (
      <div id={id} className="footer_container">
        <div className="footer_up">
          <div className="footer_logo_section">
            {/* <YMaps>
                <Map  className={`${mobile ? 'mobile-iframe' : 'iframe'}`} defaultState={{ center: [44.625040, 40.094427], zoom: 15 }}>
                  <Placemark geometry={[44.625040, 40.094427]} /> 
                  <ZoomControl />
                </Map>
            </YMaps> */}
          </div>
          <div className="footer_desription_section_left">
          <Link to={"/#toppage"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`} >Главная</div></Link> 
          <Link to={"/#example"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`} >Пример страницы</div></Link>
          <Link to={"/#howitworks"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`} >Как работает</div></Link>
          </div>
          <div className="footer_desription_section_right">
            {/* <Link to={"/#answer"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`}> Цена </div></Link> */}
            <Link to={"/#answer"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`}>Контакты</div></Link>
            <Link to={"/#feedback"}><div className={`${mobile ? 'mobile-footer_txt' : 'footer_txt'}`}>Отзывы</div></Link>
          </div> 
        </div>
        <div className={`${mobile ? 'mobile-footer_downs' : 'footer_down'}`}>
        <div className={`${mobile ? 'mobileFooter_button_container' : "footer_button_container"}`}>
          <FooterLinkButton onClick={() => { navigate(`/personalpage/#toppage`); }} mobile={mobile} width={mobile ? '70%' : '27%'} height={50} text="Обработка персональных данных" />
          <FooterLinkButton onClick={() => { navigate(`/useryespage/#toppage`); }} mobile={mobile} width={mobile ? '70%' : '27%'} height={50} text="Пользовательское соглашение" margin={mobile ? '10px 0px' : ''} />
          <FooterLinkButton onClick={() => { navigate(`/licencepage/#toppage`); }} mobile={mobile} width={mobile ? '70%' : '27%'} height={50} text="Лицензия" />
        </div>

        <div className={`${mobile ? 'mobile-container_visa_coperait' : 'container_visa_coperait'}`}>
                <div className="coperait_container">
                  <div className={`${mobile ? 'mobile-coperait_txt' : 'coperait_txt'}`}>Агапова Татьяна Александровна Инн:233907730584</div>
                  <div className={`${mobile ? 'mobile-coperait_txt' : 'coperait_txt'}`}>Тел.+7-960-327-28-01 Эл почта 📪   life777history@gmail.com </div>
                  <div className={`${mobile ? 'mobile-coperait_txt' : 'coperait_txt'}`}>Copyright © 2022-2023 inmemory-forever.com. Все права защищены</div> 
                </div>
                <div className={`${mobile ? 'mobile-visa_container' : 'visa_container'}`}>
                  <img src={visa} alt="visa" width="49" height="17" />
                  <img src={mastercard} alt="mastercard" width="35" height="23" />
                </div>
        </div>
        </div>
      </div>
    );
}

export default Footer;
