import React from 'react';  
import Biosection from '../Biosection';
import Title from '../Title'; 
import './style.css';

export default function PageBioBlock(props) {
  const { mobile, id, biosection,transparent, backg } = props; 
 
  return (
    <div style={transparent ? { background: 'transparent' } : {} } id={id} className="pageBioBlockWrapper"> 
      <Title  color={backg != '1' && backg != 'none' ?  '#fff' : backg == '2' ? '#fff' : !mobile  ?  backg == 'none' ? undefined :'#fff' :  backg == '1' ? '#fff' : undefined} size={mobile ? 30 : 50} margin="50px 0px 0px 0px" text="Биография" under /> 
      <div className={`${mobile ? 'mobilePageBioContainer' : 'pageBioContainer'}`}> 
        {
          biosection.length == 0 && <div className={`colorFon_${backg} errorDateTxt`} >Не указано</div>
        }
        {
          biosection.map((item, key) => {
            return <Biosection
            mobile={mobile}
            backg={backg}
              key={key}
              infoPhoto={item.infoPhoto}
              line 
              title={item.title}
              description={item.description}
              summary={key === 2 ? item.description : undefined}
            />
          })
        } 
        <div style={{ width:'10px', height:'10px', position:'absolute', bottom:'128px', left:'40px' }} id="medias"/>
      </div> 
    </div>
  );
}
